import React from 'react'
import { Helmet } from 'react-helmet'

const TermCondition = () => {
    return (
        <>
            <Helmet>
                <title>Milky Way 777 Slots - Terms and Conditions</title>
                <meta name="description" content="Read our comprehensive Terms and Conditions for using our site, outlining the rules, policies, and guidelines to ensure a safe and enjoyable experience." />
            </Helmet>
            <section className="all-blog">
                <div className="container">
                    <div className="blog-head blog-details">
                        <h1>Terms and Conditions</h1>
                    </div>
                    <div className="row recent-blog gy-4">
                        <div className="col-lg-12">
                            <div className="details-content">
                                <p>Welcome to MilkyWay Online! Before diving into the fun, we want to ensure you understand the guidelines that keep our platform safe, fair, and enjoyable for everyone. These terms and conditions outline what you can expect from us and what we expect from you. By using MilkyWay Online, you are agreeing to these terms, so please take a moment to read through them.</p>

                                <div className="details-one content-space" id="gaming-goal">
                                    <h3>1. Your Agreement with Us</h3>
                                    <p>When you use MilkyWay Online, you agree to follow these Terms and Conditions. If you don’t agree, that’s okay, but you won’t be able to use our services.</p>
                                </div>

                                <div className="details-one content-space" id="rating-review">
                                    <h3>2. Who Can Play</h3>
                                    <p>To join in the fun, you need to be at least 18 years old or meet the legal gambling age in your area. By signing up, you confirm that you meet this requirement.</p>
                                </div>

                                <div className="details-one content-space" id="evaluate">
                                    <h3>3. Creating an Account</h3>
                                    <p> You'll need to create an account to access certain features. When you do, please</p>
                                    <ul>
                                        <li>Provide accurate and up-to-date information.</li>
                                        <li>Keep your account details private.</li>
                                        <li>Be responsible for all actions taken under your account.</li>
                                    </ul>
                                    <p>If something seems off or you think your account has been compromised, let us know immediately!</p>
                                </div>

                                <div className="details-one content-space" id="slot-game">
                                    <h3>4. Keeping It Fun and Responsible</h3>
                                    <p>We believe gaming should be entertaining, not a financial strategy. If you ever feel like it’s becoming more than just a game, there are tools on our platform to help, like deposit limits and self-exclusion options. </p>
                                </div>

                                <div className="details-one content-space" id="bonuses">
                                    <h3>5. Deposits and Withdrawals</h3>
                                    <ul>
                                        <li>Any money you deposit must be yours and obtained legally.</li>
                                        <li>Withdrawals may require additional verification for security reasons.</li>
                                        <li>We reserve the right to hold or deny withdrawal requests if we detect anything suspicious.</li>
                                    </ul>
                                </div>

                                <div className="details-one content-space" id="android">
                                    <h3>6. Bonuses and Promotions</h3>
                                    <p>We love rewarding our players, but all bonuses and promotions come with specific terms. Be sure to read the rules for each offer to ensure you can enjoy the full benefits. If the terms are not followed, bonuses and winnings might be forfeited.</p>
                                </div>

                                <div className="details-one content-space" id="features">
                                    <h3>7. Fair Play Is Non-Negotiable</h3>
                                    <p>We are committed to providing a fair gaming environment. Activities like cheating, collusion, or using unfair tools are strictly prohibited. If we find any misconduct, your account could be suspended or closed.</p>
                                </div>

                                <div className="details-one content-space" id="try-before">
                                    <h3>8. Our Content Belongs to Us</h3>
                                    <p>Everything you see on MilkyWay Online – from our logos to our software – is owned by us and protected by law. Please don’t copy, distribute, or modify anything without our permission.</p>
                                </div>

                                <div className="details-one content-space" id="try-before">
                                    <h3>9. Your Privacy Matters</h3>
                                    <p>EUsing MilkyWay Online, you agree to our Privacy Policy, which explains how we collect and protect your information. We take your privacy seriously and use industry-standard measures to secure your data.</p>
                                </div>

                                <div className="details-one content-space" id="try-before">
                                    <h3>10. Account Suspension or Termination</h3>
                                    <p>We hope it never comes to this, but we reserve the right to suspend, restrict, or terminate your account if:</p>
                                    <ul>
                                        <li>You violate these Terms and Conditions.</li>
                                        <li>Fraudulent or suspicious activity is detected.</li>
                                        <li>We believe it’s necessary for the safety and integrity of the platform.</li>
                                    </ul>
                                </div>

                                <div className="details-one content-space" id="try-before">
                                    <h3>11. Keeping Things Up to Date</h3>
                                    <p>We may update these Terms and Conditions from time to time. When we do, we’ll make the updated version available on our website. Continuing to use MilkyWay Online after updates means agreeing to the changes</p>
                                </div>

                                <div className="details-one content-space" id="try-before">
                                    <h3>12. Your Account, Your Responsibility</h3>
                                    <p>While we take security seriously, you are responsible for keeping your login details safe. If someone else accesses your account because your credentials were compromised, we can’t be held responsible for any resulting losses.</p>
                                </div>

                                <div className="details-one content-space" id="try-before">
                                    <h3>13. Activities We Don’t Allow</h3>
                                    <p>Certain behaviors are strictly off-limits, including:</p>
                                    <ul>
                                        <li>Hacking or attempting to disrupt our platform.</li>
                                        <li>Using automated tools to gain an unfair advantage.</li>
                                        <li>Engaging in activities that could harm the integrity of our services.</li>
                                    </ul>
                                    <p>Violating these rules could result in losing access to your account or legal action.</p>
                                </div>

                                <div className="details-one content-space" id="try-before">
                                    <h3>14. Communication Preferences</h3>
                                    <p>By signing up, you agree to receive emails about promotions, updates, and important account information. If promotional emails aren’t your thing, you can opt-out, but we’ll still send you critical account notifications.</p>
                                </div>

                                <div className="details-one content-space" id="try-before">
                                    <h3>15. Third-Party Links</h3>
                                    <p>Sometimes, we may link to other websites for your convenience. However, we’re not responsible for the content or actions of those third-party sites, so please use them at your own risk.</p>
                                </div>

                                <div className="details-one content-space" id="try-before">
                                    <h3>16. Protecting MilkyWay Online</h3>
                                    <p>If your actions cause harm to us or our users, you agree to cover any related claims, costs, or damages. </p>
                                </div>

                                <div className="details-one content-space" id="try-before">
                                    <h3>17. Playing Nice in the Community</h3>
                                    <p>We want MilkyWay Online to be a welcoming space for everyone. Please treat other players with respect and follow our community guidelines. Failing to do so could result in a suspension or ban.</p>
                                </div>

                                <div className="details-one content-space" id="try-before">
                                    <h3>18. Currencies and Conversion</h3>
                                    <p>We support multiple currencies for your convenience. However, exchange rates and fees may apply when converting funds. Be sure to check the rates and any related costs before making transactions.</p>
                                </div>

                                <div className="details-one content-space" id="try-before">
                                    <h3>Need Help ?</h3>
                                    <p>
                                        We are here to make your experience at MilkyWay Online safe, fair, and enjoyable. If you have questions or need assistance, do not hesitate to contact our friendly customer support team.
                                        <br />
                                        Thank you for choosing MilkyWay Online. Let the games begin - responsibly and fairly!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default TermCondition
