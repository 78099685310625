import './ChatMessenger.css'

const ChatMessenger = () => {

    const redirectToMessenger = () => {
        window.open('https://m.me/400936276434141', '_blank');
    };
    return (
            <div className="messenger-icon">
                <button className="messenger-btn" onClick={redirectToMessenger} id="FbMessanger__button"><img src="/assets/messanger-icon.svg" alt="messanger-icon" /></button>
            </div>
    )
}

export default ChatMessenger
