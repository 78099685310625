import React from 'react'
import { NavLink } from 'react-router-dom'

const Footer = () => {
  return (
    <div className="copy-wrapper">
      <div className="footer-links">
        <ul>
          <li><NavLink className='nav-link nav-font' to='/disclaimer'>Disclaimer</NavLink></li>
          <li><NavLink className='nav-link nav-font' to='/terms-conditions'>Terms and Conditions</NavLink></li>
          <li><NavLink className='nav-link nav-font' to='/privacy-policy'>Privacy Policy</NavLink></li>
        </ul>
      </div>
      <div className="copy-right">
        © Copyright 2025 Milky Way All Rights Reserved
      </div>
    </div>
  )
}

export default Footer
