import React from 'react'
import { Helmet } from 'react-helmet'

const PrivacyPolicy = () => {
    return (
        <>
            <Helmet>
                <title>Milky Way 777 Slots - Privacy Policy</title>
                <meta name="description" content="We prioritize your privacy. Our Privacy Policy explains how we protect your data, use it responsibly, and ensure your rights are upheld." />
            </Helmet>
            <section className="all-blog">
                <div className="container">
                    <div className="blog-head blog-details">
                        <h1> Privacy Policy</h1>
                    </div>
                    <div className="row recent-blog gy-4">
                        <div className="col-lg-12">
                            <div className="details-content">
                                <p>
                                    At MilkyWay Online, we value your privacy and are committed to protecting it. This privacy policy highlights how we handle your personal information when you use our software and website.
                                    <br />
                                    At MilkyWay Online, we strive to create a safe and reliable environment. You agree to the terms outlined in this privacy policy by using our software and services.
                                </p>

                                <div className="details-one content-space" id="gaming-goal">
                                    <h3>Information We Collect</h3>
                                    <p>We do not intentionally collect personal data from you. However, please be aware of the following:</p>
                                    <ul>
                                        <li><b>Third-Party Services:</b> Advertisements and other third-party services integrated into our platform may use cookies or similar technologies to collect details about your interactions with them.
                                        </li>
                                    </ul>
                                </div>

                                <div className="details-one content-space" id="rating-review">
                                    <h3>Automatic Data Collection</h3>
                                    <p>Our software and website may automatically collect certain technical data to improve functionality, including:</p>
                                    <ul>
                                        <li><b>Device Information:</b>includes your device type, IP address, and operating system.
                                        </li>
                                        <li><b>Location Data:</b>If you give permission, we may access your location to enhance your user experience. However, this data is never shared with third parties.
                                        </li>
                                    </ul>
                                </div>

                                <div className="details-one content-space" id="evaluate">
                                    <h3>Your Rights</h3>
                                    <p>We respect your rights under applicable data protection laws. These rights include:</p>
                                    <ol>
                                        <li><b>Access:</b> You can request details about the data we collect and how it is used.</li>
                                        <li><b>Correction:</b>You can request corrections if any of your data is inaccurate.</li>
                                        <li><b>Deletion:</b> You can request the deletion of your data under specific conditions.</li>
                                        <li><b>Limitation:</b> You have the right to limit how your data is processed.</li>
                                    </ol>
                                </div>

                                <div className="details-one content-space" id="slot-game">
                                    <h3>Security and Retention</h3>
                                    <p> We take security seriously and deploy measures to protect your data. However, no system is completely safe. We retain your data only as long as it is important for our services or to meet legal requirements.</p>
                                </div>

                                <div className="details-one content-space" id="bonuses">
                                    <h3>Cookies and Similar Technologies</h3>
                                    <p>Cookies help us enhance your experience by storing small amounts of data on your device. You can manage or disable cookies through your browser settings. Please note that disabling cookies may affect the functionality of our website.</p>
                                </div>

                                <div className="details-one content-space" id="android">
                                    <h3>Updates to This Policy</h3>
                                    <p>The Privacy Policy may be updated periodically to reflect changes in our practices or for legal reasons. Updates will be posted here, and continued use of our services after changes are made constitutes your acceptance of the revised policy.</p>
                                </div>

                                <div className="details-one content-space" id="features">
                                    <h3>Agreement and Disputes</h3>
                                    <p>By using our services, you agree to the terms of this Privacy Policy. The laws of the United States will govern any disputes arising from its terms or your use of our services.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PrivacyPolicy
