import React from 'react'
import { Helmet } from 'react-helmet'

const Disclaimer = () => {
    return (
        <>
            <Helmet>
                <title>Milky Way 777 Slots - Disclaimer</title>
                <meta name="description" content="Milkyway777 Slots provides information about the app and offers assistance with its download. We do not create or manage the app." />
            </Helmet>
            <section className="all-blog">
                <div className="container">
                    <div className="blog-head blog-details">
                        <h1> Disclaimer</h1>
                    </div>
                    <div className="row recent-blog gy-4">
                        <div className="col-lg-12">
                            <div className="details-content">
                                <p>Welcome to MilkyWay. Please read this disclaimer carefully to understand the nature and responsibilities of our services.</p>
                                <br />

                                <div className="details-one  content-space">
                                    <h3>Independent Entity</h3>
                                    <p>We are an independent entity and are not the developers, maintainers, or affiliated in any way with the MilkyWay Casino app. We serve solely as an informational resource, providing details about the app and facilitating its download.
                                    </p>
                                </div>

                                <div className="details-one  content-space">
                                    <h3>No Financial Transactions</h3>
                                    <p>Our platform does not handle any type of financial transactions. This may include currency, online payments, or cryptocurrencies. We operate strictly as an informational service and have no involvement with financial dealings relatable in any way to the MilkyWay Casino app.
                                    </p>
                                </div>

                                <div className="details-one  content-space">
                                    <h3>Information and Resources</h3>
                                    <p>The main purpose of our website is to give accurate and relevant information about the MilkyWay Casino app, its features, and a convenient download link. However, we do not guarantee the availability or functionality of the app as we do not develop or maintain it.
                                    </p>
                                </div>

                                <div className="details-one  content-space">
                                    <h3>Limitation of Liability</h3>
                                    <p>While we aim to provide accurate and up-to-date information, we cannot be held responsible for any damage or loss, whether direct or indirect, resulting from using our website or the MilkyWay Casino app.
                                    </p>
                                </div>

                                <div className="details-one  content-space">
                                    <h3>Inherent Risks of Casino Apps</h3>
                                    <p>
                                        There are inherent risks associated with casino apps, including the MilkyWay Casino app. We are not responsible or liable for any bad effects, negative results, or financial losses that may result from using such applications. Users are advised to exercise caution and understand the risks associated with gambling.
                                        <br />
                                        By using our platform, you acknowledge and agree to this disclaimer. If you have any specific concerns about the MilkyWay Casino app, we recommend contacting its official developers or support team.
                                    </p>
                                </div>

                                <div className="details-one  content-space">
                                    <h3>Limitation of Liability</h3>
                                    <p>While we aim to provide accurate and up-to-date information, we cannot be held responsible for any damage or loss, whether direct or indirect, resulting from using our website or the MilkyWay Casino app.
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Disclaimer
